import { Card, message } from "antd";
import React, { useEffect, useState } from "react";
import UsersModal from "../../../components/Modal/UsersModal/UsersModal";
import SapaButton from "../../../ui/Button/Button";
import DashboardCard from "../../Dashboard/DashboardCard";
import { useLocation } from "react-router-dom";
import { formatAmount } from "../../../helpers/numbers";
import ActionHandler from "../../../components/ActionHandler/ActionHandler";
import Chip from "../../../components/Chip/Chip";
import { useFormik } from "formik";
import { updateUser } from "../../../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import ProgressLoader from "../../../components/Loader/ProgressLoader";
import { selectUpdateUserLoading } from "../../../redux/selectors/userSelectors";


const UserOverview = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const isLoading = useSelector(selectUpdateUserLoading);

  const onClose = () => {
    setOpen(false);
  };

  const handleChange = (key, value) => {
    setData({ ...data, [key]: value });
  }

  const formik = useFormik({
    initialValues: data,
    onSubmit: (values) => {
      dispatch(updateUser(values)).then((res) => {
        message.success(res?.message);
      }).catch((err) => {
        message.error(err);
      });
    }
  });


  useEffect(() => {
    const stateData = location.state;

    const filteredData = {
      id: stateData?.id,
      first_name: stateData?.user_profile?.first_name ?? '',
      last_name: stateData?.user_profile?.last_name ?? '',
      phone_number: stateData?.phone_number ?? '',
      email: stateData?.email ?? '',
      balance: stateData?.wallet?.main_balance,
      total_transactions: stateData?.total_transactions ?? 0,
      current_tier: stateData?.tier,
    };

    setData(filteredData);

  }, [location.state]);


  const handleSaveChanges = () => {
    // Manually trigger form submission
    formik.submitForm();
  };

  return (
   <ProgressLoader isLoading={isLoading}>
     <div className="users-overview">
      <Card className="users-overview__container">
        <div className="users-overview__dashboard-card-container">
          <DashboardCard
            title="Wallet Balance"
            value={formatAmount(data?.balance ?? "0.00", "₦")}
            showButton={true}
            record={data}
          />
          <DashboardCard title="Total Transactions" value={data?.total_transactions} />
          <DashboardCard title="KYC Status" value={data?.current_tier ? "BVN Verified" : 'BVN Not Verified'} />
        </div>
        <div className="users-overview__lower-section">
          <div>
            <p>User Profile</p>
            <ActionHandler actionButton={<Chip label="View more details" style={{ fontWeight: 700 }} />} actionButtonComponent={<UsersModal data={location.state} />} />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="span-container">
              <span>
                <p>First Name</p>
                <input
                  type="text"
                  placeholder={"John"}
                  value={data?.first_name ?? ""}
                  onChange={(e) => handleChange('first_name', e.target.value)}
                />
              </span>
              <span>
                <p>Last Name</p>
                <input
                  type="text"
                  placeholder={"Doe"}
                  value={data?.last_name ?? ""}
                  onChange={(e) => handleChange('last_name', e.target.value)}
                />
              </span>
            </div>
            <div>
              <p>Mobile number</p>
              <input
                type={"number"}
                placeholder={"08123456789"}
                value={data?.phone_number ?? ""}
                onChange={(e) => handleChange('phone_number', e.target.value)}
              />
            </div>
            <div>
              <p>
                Email address <i>(optional)</i>
              </p>
              <input
                type={"email"}
                placeholder={"john.doe@gmail.com"}
                value={data?.email ?? ""}
                onChange={(e) => handleChange('email', e.target.value)}
              />
            </div>
            <div>
              <SapaButton loading={isLoading} label={"Save Changes"} onClick={handleSaveChanges} />
            </div>
          </form>
        </div>
      </Card>
      <UsersModal open={open} onClose={onClose} />
    </div>
   </ProgressLoader>
  );
};

export default UserOverview;
