import authReducer from "./authReducer";
import modalReducer from "./modalReducer";
import servicesReducer from './servicesReducer';
import usersReducer from './usersReducer';
import transactionsReducer from './transactionsReducer';
import providersReducer from './providersReducer';
import dashboardReducer from "./dashboardReducer";
import verificationsReducer from "./verificationsReducer";
import incomingRequestsReducer from './incomingRequestsReducer';

const reducers = {
  auth: authReducer,
  service: servicesReducer,
  user: usersReducer,
  transaction: transactionsReducer,
  provider: providersReducer,
  dashboard: dashboardReducer,
  verification: verificationsReducer,
  modal: modalReducer,
  incomingRequests: incomingRequestsReducer,
};

export default reducers;
