import { formatAmount } from './../../helpers/numbers';
import ServicesModal from "../../components/Modal/ServicesModal/ServicesModal";
import ApiProviderModal from "../../components/Modal/ApiProviderModal/ApiProviderModal";
import TransactionModal from "../../components/Modal/TransactionModals/TransactionModal";
import ActionHandler from "../../components/ActionHandler/ActionHandler";
import Chip from "../../components/Chip/Chip";
import { requeryIncomingRequest } from '../../redux/actions/incomingRequest';
import { selectIncomingRequestRequeryLoading } from '../../redux/selectors/incomingRequestSelectors';

const formatDate = (dt) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dt).toLocaleDateString("en-US", options);
};

export const columns = [
  {
    width: "16.66%",
    title: "DATE & TIME",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (text) => formatDate(text),
  },
  {
    width: "10%",
    title: "CUSTOMER",
    dataIndex: "user",
    sorter: true,
    key: "customer",
    render: (value, record) => value?.user_profile?.full_name?? "GUEST"
  },
  {
    width: "14%",
    title: "TRANSACTION TYPE",
    dataIndex: "req",
    sorter: true,
    key: "transaction_type",
    render: (value, record) => value?.service_type? value?.service_type?.toUpperCase() : record?.transaction_type?.toUpperCase(),
  },
  {
    width: "14%",
    title: "PROCESSOR",
    dataIndex: "provider",
    sorter: true,
    key: "provider",
    render: (value, record) => value?.name?? "INTERNAL"
  },
  {
    width: "12%",
    title: "PAYMENT METHOD",
    dataIndex: "payment_method",
    key: "payment_method",
  },
  {
    width: "16.66%",
    title: "REFERENCE",
    dataIndex: "transaction_reference",
    key: "reference",
  },
  {
    width: "11%",
    title: "AMOUNT",
    dataIndex: "amount",
    key: "amount",
    render: (value) => {
      return formatAmount(value?? 0);
    }
  },
  {
    width: "8%",
    title: "STATUS",
    dataIndex: "status",
    key: "status",
  },
  {
    width: "16.66%",
    title: "ACTION",
    dataIndex: "action",
    key: "action",
    render: (text, record) => <ActionHandler
      isDropdown={true}
      items={[
        { label: "View details", value: "view_details", component: <TransactionModal data={record} /> },
        { label: "Print receipt", value: "print_receipt", component: <TransactionModal data={record} /> },
        { label: <span style={{ color: "#F04438", fontWeight: 600 }}>Authorize Refund</span>, value: "authorize_refund" },
      ]}
    />,
  },
];
export const servicesColumn = [
  {
    width: "40%",
    title: "SERVICE NAME",
    dataIndex: "name",
    sorter: true,
    key: "serviceName",
  },

  {
    width: "20%",
    title: "PROVIDERS",
    dataIndex: "total_providers_assigned",
    key: "providers"
  },
  {
    width: "25%",
    title: "CURRENT PROVIDER",
    dataIndex: "active_provider",
    key: "currentProvider",
    render: (value) => value?.name ?? '',
  },
  {
    width: "15%",
    title: "ACTION",
    dataIndex: "id",
    key: "action",
    render: (value, record) => {
      return <ActionHandler actionButton={<Chip label="Switch" />} actionButtonComponent={<ServicesModal data={record} />} />
    }
  },
];

export const serviceCategoriesColumn = [
  {
    width: "22.5%",
    title: "CATEGORY",
    dataIndex: "name",
    sorter: true,
    key: "serviceName",
  },
  {
    width: "22.5%",
    title: "SERVICES",
    dataIndex: "service_counts",
    sorter: true,
    key: "serviceCounts"
  },
  {
    width: "20%",
    title: "DESCRIPTION",
    dataIndex: "description",
    key: "description"
  },
  {
    width: "20%",
    title: "STATUS",
    dataIndex: "status",
    key: "status",
  },
  {
    width: "15%",
    title: "ACTION",
    dataIndex: "slug",
    key: "action",
    render: (value) => {
      return <ActionHandler actionButton={<Chip label="View Details" />} redirectTo={`/dashboard/services/${value}`} />
    }
  },
];

export const apiProvidersColumn = [
  {
    width: "30%",
    title: "PROVIDER NAME",
    dataIndex: "name",
    sorter: true,
    key: "providerName",
  },
  {
    width: "25%",
    title: "WALLET BALANCE",
    dataIndex: "balance",
    key: "balance",
  },
  {
    width: "25%",
    title: "TOTAL TRANSACTIONS",
    dataIndex: "transaction_counts",
    key: "transactionCounts",
  },
  {
    width: "15%",
    title: "ACTION",
    dataIndex: "action",
    key: "action",
    render: (value, record) => {
      return <ActionHandler actionButton={<Chip label="Update" />} actionButtonComponent={<ApiProviderModal data={record} />} />
    }
  },
];

export const incomingRequestsColumn = [
  {
    width: "15%",
    title: "PROVIDER",
    dataIndex: "name",
    sorter: true,
    key: "providerName",
    render: (value, record) => {
      return <div style={{maxWidth: 200}}>
        {record?.name} <br /><br />
        <strong>Transaction Ref:</strong> {record?.req?.reference}
      </div>
    }
  },
  {
    width: "10%",
    title: "AMOUNT PAID",
    dataIndex: "req",
    key: "req",
    render: (value) => {
      return formatAmount(value?.amount?? 0);
    }
  },
  {
    width: "10%",
    title: "SETTLEMENT AMOUNT",
    dataIndex: "req",
    key: "req",
    render: (value) => {
      return formatAmount(value?.settled_amount?? 0);
    }
  },
  {
    width: "10%",
    title: "FEE",
    dataIndex: "req",
    key: "req",
    render: (value) => {
      return formatAmount(value?.fee?? 0);
    }
  },
  {
    width: "10%",
    title: "ACCOUNT",
    dataIndex: "req",
    key: "req",
    render: (value) => {
      return value?.account_number;
    }
  },
  {
    width: "10%",
    title: "ORIGINATOR",
    dataIndex: "req",
    key: "req",
    render: (value) => {
      return <div style={{maxWidth: 200, padding: 4}}>{value?.originator}</div>;
    }
  },
  {
    width: "10%",
    title: "REMARK",
    dataIndex: "req",
    key: "req",
    render: (value) => {
      return <div style={{maxWidth: 200, padding: 4}}>{value?.description}</div>;
    }
  },
  {
    width: "10%",
    title: "ACTION",
    dataIndex: "action",
    key: "action",
    render: (value, record) => {
      return <ActionHandler 
        actionButton={<Chip label="Repush"  />} 
        dispatchAction={requeryIncomingRequest} 
        dispatchActionData={{id: record?.id} }
      />
    }
  },
];

export const overviewColumn = [
  {
    width: "28%",
    title: "SERVICE NAME",
    dataIndex: "serviceName",
    sorter: true,
    key: "serviceName",
  },
  {
    width: "28%",
    title: "CURRENT COMMISSION",
    dataIndex: "currentCommission",
    sorter: true,
    key: "currentCommission",
  },
  {
    width: "28%",
    title: "UPDATE COMMISSION",
    dataIndex: "updateCommission",
    key: "updateCommission",
  },
  {
    width: "16%",
    title: "ACTION",
    dataIndex: "action",
    key: "action",
  },
];

export const usersColumn = [
  {
    width: "20%",
    title: "DATE JOINED",
    dataIndex: "created_at",
    sorter: true,
    key: "date",
    render: (value) => formatDate(value),
  },
  {
    width: "20%",
    title: "FULL NAME",
    dataIndex: "user_profile",
    sorter: true,
    key: "full_name",
    render: (value) => value?.full_name?? "Guest User",
  },
  {
    width: "16.66%",
    title: "PHONE NUMBER",
    dataIndex: "phone_number",
    sorter: true,
    key: "phone_number",
  },
  {
    width: "16.66%",
    title: "EMAIL ADDRESS",
    dataIndex: "email",
    sorter: true,
    key: "email_address",
  },
  {
    width: "16.66%",
    title: "WALLET BALANCE",
    dataIndex: "wallet",
    key: "balance",
    render: (value) => {
      return formatAmount(value?.main_balance ?? 0);
    }
  },
  {
    width: "16.66%",
    title: "STATUS",
    dataIndex: "status",
    sorter: true,
    key: "status",
  },
  {
    width: "16.66%",
    title: "ACTION",
    dataIndex: "action",
    key: "action",
    render: (text, record) => <ActionHandler
      isDropdown={true}
      items={[
        { label: "Edit profile", value: "edit_profile", redirectTo: "/dashboard/users/overview", data: record },
        { label: "View transactions", value: "view_transactions", redirectTo: "/dashboard/users/transactions", data: record },
        { label: "Update Commissions", value: "update_commissions", redirectTo: "/dashboard/users/commissions", data: record },
        { label: <span style={{ color: "#F04438", fontWeight: 600 }}>Block user</span>, value: "block_user" },
      ]}
    />,
  },
];

export const alertsColumn = [
  {
    width: "17%",
    title: "DATE JOINED",
    dataIndex: "date",
    sorter: true,
    key: "date",
  },
  {
    width: "73.5%",
    title: "NOTE",
    dataIndex: "note",
    key: "note",
  },
  {
    width: "10%",
    title: "ACTION",
    dataIndex: "action",
    key: "action",
  },
];

export const administratorsColumn = [
  {
    width: "30%",
    title: "DATE ADDED",
    dataIndex: "date",
    sorter: true,
    key: "date",
  },
  {
    width: "30%",
    title: "EMAIL ADDRESS",
    dataIndex: "email",
    key: "email",
  },
  {
    width: "27.5%",
    title: "FULL NAME",
    dataIndex: "name",
    key: "name",
  },
  {
    width: "12.5%",
    title: "ACTION",
    dataIndex: "action",
    key: "action",
  },
];
