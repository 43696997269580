import { all, fork } from "redux-saga/effects";
import authSaga from "./auth.saga";
import modalSaga from "./modal.saga";
import userSaga from "./user.saga";
import dashboardSaga from "./dashboard.saga";
import serviceSaga from './service.saga';
import providerSaga from "./provider.saga";
import transactionSaga from './transaction.saga';
import verificationSaga from "./verification.saga";
import incomingRequestSaga from "./incomingRequest.saga";

export default function* rootSaga() {
  const rootSaga = [
    fork(authSaga), 
    fork(modalSaga), 
    fork(userSaga), 
    fork(dashboardSaga), 
    fork(serviceSaga),
    fork(providerSaga),
    fork(transactionSaga),
    fork(verificationSaga),
    fork(incomingRequestSaga)
  ];

  yield all(rootSaga);
}
