import React from "react";
import { Statistic, Card, Avatar } from "antd";
import infoCircle from "../../assets/svgs/Dashboard-icons/Info-Circle.svg";
import ActionHandler from "../../components/ActionHandler/ActionHandler";
import Chip from "../../components/Chip/Chip";
import UpdateUserBalanceModal from "../../components/Modal/ApiProviderModal/UpdateUserBalanceModal";

const DashboardCard = ({ title, value, record, showButton }) => {
  return (
    <div className="dashboard-card__container">
      <Card>
        <div className="dashboard-card__container_inner">
          <Statistic
            className="dashboard-card__topSection"
            title={title}
            value={value}
          />
          <div className="dashboard-card__btn-container">
            <Avatar
              className="dashboard-card__topSection_icon"
              src={infoCircle}
            ></Avatar>
            {showButton && <ActionHandler actionButton={<Chip label="Update Balance" />} actionButtonComponent={<UpdateUserBalanceModal data={record} />} />}
            
          </div>
        </div>
      </Card>
    </div>
  );
};

DashboardCard.defaultProps = {
  showButton: false,
};

export default DashboardCard;
