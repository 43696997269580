import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";


const Chip = ({ label, onClick, style, loading = false }) => {
    return (
    <span
        style={
            {
                backgroundColor: '#e8e8ff',
                color: "#9747ff",
                fontWeight: 500,
                fontSize: "11px",
                padding: "6px 16px",
                borderRadius: "26px",
                cursor: "pointer",
                ...style
            }
        }
        onClick={onClick}
    >
        {loading? <Spin size="small" style={{color: 'blue'}} indicator={<LoadingOutlined />} /> : label}
    </span>);
}

export default Chip;