import React, { useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SpPagination = ({ total = 0, defaultCurrent = 1, pageSize = 10, onChange }) => {
  // Validate and sanitize inputs
  const validPageSize = Math.max(1, pageSize); // Prevent division by 0
  const validTotal = Math.max(0, total); // Ensure total is non-negative
  const totalPages = Math.max(1, Math.ceil(validTotal / validPageSize)); // Ensure at least 1 page

  // Initialize state for the current page
  const [currentPage, setCurrentPage] = useState(
    Math.max(1, Math.min(defaultCurrent, totalPages))
  );

  // Handle page selection changes
  const handleSelectChange = (value) => {
    setCurrentPage(value);
    if (typeof onChange === 'function') {
      onChange(value);
    }
  };

  // Generate options for the Select component
  const pageOptions = validTotal === 0 ? [] : Array.from({ length: totalPages }, (_, i) => i + 1);

  // Calculate the range of items displayed on the current page
  const startItem = validTotal === 0 ? 0 : (currentPage - 1) * validPageSize + 1;
  const endItem = Math.min(startItem + validPageSize - 1, validTotal);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        margin: '32px 24px',
      }}
    >
      {/* Page selection on the left */}
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        Page
        <Select
          aria-label="Select page"
          value={currentPage}
          style={{ width: 70, borderRadius: 8 }}
          onChange={handleSelectChange}
        >
          {pageOptions.map((page) => (
            <Option key={page} value={page}>
              {page}
            </Option>
          ))}
        </Select>
        of {totalPages}
      </div>

      {/* Item range on the right */}
      <div style={{ marginRight: 24 }}>
        {validTotal === 0
          ? 'No records to display.'
          : `Showing ${startItem} to ${endItem} of ${validTotal}`}
      </div>
    </div>
  );
};

export default SpPagination;
