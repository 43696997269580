// sagas/incomingRequestsSaga.js

import { call, all, takeEvery } from 'redux-saga/effects';
import {
  listIncomingRequestsRequest,
  searchIncomingRequestsRequest,
  filterIncomingRequestsRequest,
  exportIncomingRequestsRequest,
  getIncomingRequestInfoRequest,
  updateIncomingRequestInfoRequest,
  requeryIncomingRequestRequest,
  addIncomingRequestRequest
} from '../../api/incomingRequest'; // API imports
import {
  listIncomingRequests,
  searchIncomingRequests,
  filterIncomingRequests,
  exportIncomingRequests,
  getIncomingRequestInfo,
  updateIncomingRequestInfo,
  requeryIncomingRequest,
  addIncomingRequest
} from '../actions/incomingRequest'; // Promise actions

import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';
import { translateErrorResponse } from '../../utils/utils';

// Saga to handle listing incoming requests
export function* handleListIncomingRequests(action) {
  try {
    const response = yield call(listIncomingRequestsRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle searching incoming requests
export function* handleSearchIncomingRequests(action) {
  try {
    const response = yield call(searchIncomingRequestsRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle filtering incoming requests
export function* handleFilterIncomingRequests(action) {
  try {
    const response = yield call(filterIncomingRequestsRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle exporting incoming requests
export function* handleExportIncomingRequests(action) {
  try {
    const response = yield call(exportIncomingRequestsRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle viewing incoming request details
export function* handleGetIncomingRequestInfo(action) {
  try {
    const response = yield call(getIncomingRequestInfoRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle updating incoming request info
export function* handleUpdateIncomingRequestInfo(action) {
  try {
    const response = yield call(updateIncomingRequestInfoRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle updating incoming request balance
export function* handleIncomingRequestRequery(action) {
  try {
    const response = yield call(requeryIncomingRequestRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle adding an incoming request
export function* handleAddIncomingRequest(action) {
  try {
    const response = yield call(addIncomingRequestRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Root saga to watch for incoming request actions
export default function* incomingRequestsSaga() {
  yield all([
    takeEvery(listIncomingRequests.trigger, handleListIncomingRequests), // Watch for listIncomingRequests action
    takeEvery(searchIncomingRequests.trigger, handleSearchIncomingRequests), // Watch for searchIncomingRequests action
    takeEvery(filterIncomingRequests.trigger, handleFilterIncomingRequests), // Watch for filterIncomingRequests action
    takeEvery(exportIncomingRequests.trigger, handleExportIncomingRequests), // Watch for exportIncomingRequests action
    takeEvery(getIncomingRequestInfo.trigger, handleGetIncomingRequestInfo), // Watch for getIncomingRequestInfo action
    takeEvery(updateIncomingRequestInfo.trigger, handleUpdateIncomingRequestInfo), // Watch for updateIncomingRequestInfo action
    takeEvery(requeryIncomingRequest.trigger, handleIncomingRequestRequery), // Watch for requeryIncomingRequest action
    takeEvery(addIncomingRequest.trigger, handleAddIncomingRequest), // Watch for addIncomingRequest action
  ]);
}
