import { createPromiseAction } from '@adobe/redux-saga-promise';

// Actions for listing incoming requests
export const listIncomingRequests = createPromiseAction('incomingRequests/list');

// Actions for searching incoming requests
export const searchIncomingRequests = createPromiseAction('incomingRequests/search');

// Actions for filtering incoming requests
export const filterIncomingRequests = createPromiseAction('incomingRequests/filter');

// Actions for exporting incoming requests
export const exportIncomingRequests = createPromiseAction('incomingRequests/export');

// Actions for viewing incoming request details
export const getIncomingRequestInfo = createPromiseAction('incomingRequests/info');

// Actions for updating incoming request info
export const updateIncomingRequestInfo = createPromiseAction('incomingRequests/updateInfo');

// Actions for updating incoming request balance
export const requeryIncomingRequest = createPromiseAction('incomingRequests/requery');

// Actions for adding an incoming request
export const addIncomingRequest = createPromiseAction('incomingRequests/add');
