import React from "react";
import SpDrawer from "../SpDrawer/SpDrawer";
import SpInput from "../../SpInput/SpInput";
import SpSelect from "../../SpSelect/SpSelect";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { listUsers, updateWalletBalance } from "../../../redux/actions/user";
import { selectUpdateWalletBalanceLoading } from "../../../redux/selectors/userSelectors";

const UpdateUserBalanceModal = ({ onClose, open, data }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectUpdateWalletBalanceLoading);

  const formik = useFormik({
    initialValues: {
      operation_type: '',
      amount: '',
      id: data?.id,
      remark: '',
    },
    onSubmit: (values) => {
      dispatch(updateWalletBalance(values)).then((res) => {
        message.success(res?.message);
        dispatch(listUsers());
      }).catch((err) => {
        message.error(err);
      });
    }
  });

  const handleSaveChanges = () => {
    // Manually trigger form submission
    formik.submitForm();
  };

  const handleChange = (key, value) => {
    formik.setFieldValue(key, value); // Use Formik's setFieldValue to update form state
  };

  return (
    <SpDrawer
      title={`Update Customer Balance`}
      onClose={onClose}
      open={open}
      onSaveChanges={handleSaveChanges} // Trigger form submission on Save
      saveButtonLoading={isLoading}
    >
      <p>Customer Details<br /><strong style={{fontSize: 14}}>{`${data?.first_name} ${data?.last_name}`} (Mobile No: {`${data?.phone_number}`})</strong></p>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ marginBottom: 24, marginTop: 24 }}>
          <SpSelect
            htmlFor="updateType"
            label="Update Type"
            options={[
              { label: "Credit", value: 'credit' },
              { label: "Debit", value: 'debit' },
            ]}
            value={formik.values.operation_type}
            onChange={(value) => handleChange('operation_type', value)}
          />
        </div>

        <div style={{ marginBottom: 24 }}>
          <SpInput
            numericOnly={true}
            htmlFor="amount"
            label="Amount"
            value={formik.values.amount}
            onChange={(e) => handleChange('amount', e.target.value)}
          />
        </div>

        <div style={{ marginBottom: 24 }}>
          <SpInput
            htmlFor="remark"
            label="Remark"
            value={formik.values.amount}
            onChange={(e) => handleChange('remark', e.target.value)}
          />
        </div>
      </form>
    </SpDrawer>
  );
};

export default UpdateUserBalanceModal;
