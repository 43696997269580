import { createReducer } from '@reduxjs/toolkit';
import {
  listUsers,
  filterUsers,
  searchUsers,
  exportUsers,
  blockUser,
  unblockUser,
  deleteUser,
  updateUser,
  getUserInfo,
  updateWalletBalance,
  updateUserCommission,
  listUserCommissions,
  getUserTransactionTrace,
} from '../actions/user';

const initialState = {
  users: {
    data: [],            // List of users (includes filtered or search results)
    currentPage: 1,      // Current page for pagination
    totalRecords: 0,       // Total number of records
    pageSize: 10,        // Items per page
    nextPageUrl: null,
    prevPageUrl: null
  },
  loading: false,        // Loading state for listing, filtering, or searching users
  error: null,           // General error state

  // Export state as an object
  export: {
    loading: false,      // Loading state for export action
    success: false,      // Tracks if export was successful
    error: null,         // Export-specific error
  },

  // States for blocking, unblocking, deleting, and updating users
  block: {
    success: false,
    error: null,
  },
  unblock: {
    success: false,
    error: null,
  },
  delete: {
    success: false,
    error: null,
  },
  update: {
    success: false,
    error: null,
  },

  // States for getting user info and updating wallet balance
  getUserInfo: {
    data: null,
    loading: false,
    error: null,
  },
  updateWalletBalance: {
    loading: false,
    error: null,
  },
  updateUserCommission: {
    success: false,
    error: null,
  },
  listUserCommissions: {
    data: [],            // List of user commissions
    loading: false,
    error: null,
  },
  
  // State for getting user transaction trace
  getUserTransactionTrace: {
    data: null,          // Transaction trace data
    loading: false,
    error: null,
  },
};

const usersReducer = createReducer(initialState, {
  // Listing users (with pagination)
  [listUsers.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [listUsers.resolved]: (state, action) => {
    const { 
      data: users, 
      current_page: currentPage, 
      next_page_url: nextPageUrl, 
      prev_page_url: prevPageUrl, 
      total: totalRecords, 
      per_page: pageSize 
    } = action.payload;

    state.loading = false;
    state.users = {
      data: users,
      currentPage, 
      totalRecords, 
      pageSize,
      nextPageUrl,
      prevPageUrl 
    };
  },
  [listUsers.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Handle listing error
  },

  // Filter users
  [filterUsers.trigger]: (state) => {
    state.loading = true;
    state.error = null;
    state.users = {};
  },
  [filterUsers.resolved]: (state, action) => {
    const { data: users, currentPage, totalRecords, pageSize } = action.payload;
    state.loading = false;
    state.users = {
      data: users,    // Set fetched users data
      currentPage,    // Update current page from the backend
      totalRecords,     // Update total pages from the backend
      pageSize,       // Update page size (if provided by backend)
    };
  },
  [filterUsers.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Handle filtering error
  },

  // Search users
  [searchUsers.trigger]: (state) => {
    state.loading = true;
    state.error = null;
    state.users = {};
  },
  [searchUsers.resolved]: (state, action) => {
    const { data: users, currentPage, totalRecords, pageSize } = action.payload;
    state.loading = false;
    state.users = {
      data: users,    // Set fetched users data
      currentPage,    // Update current page from the backend
      totalRecords,     // Update total pages from the backend
      pageSize,       // Update page size (if provided by backend)
    };
  },
  [searchUsers.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Handle search error
  },

  // Export users
  [exportUsers.trigger]: (state) => {
    state.export.loading = true;  // Set export-specific loading
    state.export.success = false; // Reset success status
    state.export.error = null;    // Clear export errors
  },
  [exportUsers.resolved]: (state) => {
    state.export.loading = false; // Stop export loading
    state.export.success = true;  // Set export success flag
  },
  [exportUsers.rejected]: (state, action) => {
    state.export.loading = false; // Stop export loading
    state.export.success = false; // Mark export as unsuccessful
    state.export.error = action.payload; // Handle export error
  },

  // Block user
  [blockUser.trigger]: (state) => {
    state.block.error = null;
  },
  [blockUser.resolved]: (state) => {
    state.block.success = true;
  },
  [blockUser.rejected]: (state, action) => {
    state.block.success = false;
    state.block.error = action.payload;
  },

  // Unblock user
  [unblockUser.trigger]: (state) => {
    state.unblock.error = null;
  },
  [unblockUser.resolved]: (state) => {
    state.unblock.success = true;
  },
  [unblockUser.rejected]: (state, action) => {
    state.unblock.success = false;
    state.unblock.error = action.payload;
  },

  // Delete user
  [deleteUser.trigger]: (state) => {
    state.delete.error = null;
  },
  [deleteUser.resolved]: (state) => {
    state.delete.success = true;
  },
  [deleteUser.rejected]: (state, action) => {
    state.delete.success = false;
    state.delete.error = action.payload;
  },

  // Update user details
  [updateUser.trigger]: (state) => {
    state.update.error = null;
    state.update.loading = true;
  },
  [updateUser.resolved]: (state) => {
    state.update.error = null;
    state.update.loading = false;
  },
  [updateUser.rejected]: (state, action) => {
    state.update.loading = false;
    state.update.error = action.payload;
  },

  // Get user info
  [getUserInfo.trigger]: (state) => {
    state.getUserInfo.loading = true;
    state.getUserInfo.error = null;
  },
  [getUserInfo.resolved]: (state, action) => {
    state.getUserInfo.loading = false;
    state.getUserInfo.data = action.payload; // Set fetched user info
  },
  [getUserInfo.rejected]: (state, action) => {
    state.getUserInfo.loading = false;
    state.getUserInfo.error = action.payload; // Handle error for getting user info
  },

  // Update wallet balance
  [updateWalletBalance.trigger]: (state) => {
    state.updateWalletBalance.loading = true;
    state.updateWalletBalance.error = null;
  },
  [updateWalletBalance.resolved]: (state) => {
    state.updateWalletBalance.loading = false;
    state.updateWalletBalance.error = null;
  },
  [updateWalletBalance.rejected]: (state, action) => {
    state.updateWalletBalance.loading = false;
    state.updateWalletBalance.error = action.payload; // Handle error for updating wallet balance
  },

  // Update user commission
  [updateUserCommission.trigger]: (state) => {
    state.updateUserCommission.error = null;
  },
  [updateUserCommission.resolved]: (state) => {
    state.updateUserCommission.success = true;
  },
  [updateUserCommission.rejected]: (state, action) => {
    state.updateUserCommission.success = false;
    state.updateUserCommission.error = action.payload; // Handle error for updating user commission
  },

  // List user commissions
  [listUserCommissions.trigger]: (state) => {
    state.listUserCommissions.loading = true;
    state.listUserCommissions.error = null;
  },
  [listUserCommissions.resolved]: (state, action) => {
    
    const { 
      data: commissions, 
      current_page: currentPage, 
      next_page_url: nextPageUrl, 
      prev_page_url: prevPageUrl, 
      total: totalRecords, 
      per_page: pageSize 
    } = action.payload;

    state.listUserCommissions = {
      data: commissions,    // Set filtered services data
      currentPage,       // Update pagination after filtering
      totalRecords,
      pageSize,
      nextPageUrl,
      prevPageUrl,
      loading: false,
    };

  },
  [listUserCommissions.rejected]: (state, action) => {
    state.listUserCommissions.loading = false;
    state.listUserCommissions.error = action.payload; // Handle error for listing user commissions
  },

  // Get user transaction trace
  [getUserTransactionTrace.trigger]: (state) => {
    state.getUserTransactionTrace.loading = true;
    state.getUserTransactionTrace.error = null;
  },
  [getUserTransactionTrace.resolved]: (state, action) => {
    state.getUserTransactionTrace.loading = false;
    state.getUserTransactionTrace.data = action.payload; // Set fetched transaction trace
  },
  [getUserTransactionTrace.rejected]: (state, action) => {
    state.getUserTransactionTrace.loading = false;
    state.getUserTransactionTrace.error = action.payload; // Handle error for getting transaction trace
  },
});

export default usersReducer;
